<template>
    <!-- 百度地图  -->
    <div id="bai-du-map" v-loading="!map">
        <div class="map-top" style="position: absolute;z-index: 99;">
            <span>{{$t('i18n_66')}}</span>
            <el-autocomplete
                    popper-class="my-autocomplete"
                    v-model="keyword"
                    id="keyword"
                    style="width: 100%"
                    size="mini"
                    ref="hock"
                    :fetch-suggestions="querySearch"
                    @select="handleSelect">
                <template slot-scope="{ item }">
                    <div class="name">{{ item.title }}</div>
                    <span class="addr">{{ item.location }}</span>
                </template>
            </el-autocomplete>
        </div>
        <div class="positonbtn" @click="getCurrentLocation"><i class="el-icon-aim"></i></div>
        <info ref="Add" />
    </div>
</template>

<script>
    import AMapLoader from "@amap/amap-jsapi-loader";
    import info from "./c/info.vue"
    import setting from "@/config/setting";

    var map

    export default {
        components:{info},
        props : ["iptId"],
        data() {
            return {
                map : null,
                mouseTool : null,
                overlays : [],
                auto : null,
                placeSearch : null,
                keyword:'',
                pumpInfo:[],
                infoWindow:null,
                mapwindow:{},
                mapmarker:null,
            }
        },
        methods : {
            getFocus(){
                this.$refs.hock.focus()
            },
            querySearch(queryString, cb) {
                console.log(queryString)
                var restaurants = this.pumpInfo;
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results);
            },
            createFilter(queryString) {
                return (restaurant) => {
                    return ( restaurant.title.search(queryString)!=-1);
                };
            },
            handleSelect(item) {
                var position = new AMap.LngLat(item.longitude, item.latitude);  // 标准写法
                this.map.setCenter(position);
            },
            initMap() {
                AMapLoader.load({
                    "key": "ce454803490114760a964c216dca1f30", // 申请好的Web端开发者Key，首次调用 load 时必填
                    // "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                    "plugins": ['AMap.PlaceSearch','AMap.AutoComplete','AMap.Scale','AMap.Geolocation'],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                    // "plugins": [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                }).then((AMap)=>{
                    this.map = new AMap.Map('bai-du-map',{
                        viewMode : "2D",  //  是否为3D地图模式
                        zoom : 13,   // 初始化地图级别
                        center : [113.65553,34.748764], //中心点坐标  郑州
                        resizeEnable: true,
                        lang: localStorage.getItem('lang')
                    });

                    // this.auto = new AMap.AutoComplete({
                    //     input : 'keyword'  // 搜索框的id
                    // });
                    this.placeSearch = new AMap.PlaceSearch({
                        map: this.map,
                        panel: "panel", // 结果列表将在此容器中进行展示。
                        city: "010", // 兴趣点城市
                        autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
                        extensions: 'base' //返回基本地址信息
                    });
                    this.addLocation()
                    this.getCurrentLocation();//获取当前定位
                    // this.auto.on("select", this.select);//注册监听，当选中某条记录时会触发
                }).catch(e => {
                    console.log(e);
                });
            },

            select(e) {
                console.log(e)
                this.placeSearch.setCity(e.poi.adcode);
                this.placeSearch.search(e.poi.name);  //关键字查询查询
            },
            //获取当前定位
            getCurrentLocation(){
                const that = this;
                let nowPumpId = setting.takePumpId()
                if(nowPumpId){
                    if(that.pumpInfo.length){
                        that.pumpInfo.forEach((e)=>{
                            if(e.id==nowPumpId){
                                var position = new AMap.LngLat(e.longitude, e.latitude);  // 标准写法
                                that.map.setCenter(position);
                            }
                        })
                    }
                }else{
                    var position = new AMap.LngLat(that.pumpInfo[0].longitude, that.pumpInfo[0].latitude);  // 标准写法
                    that.map.setCenter(position);
                } 
            },
            //解析定位结果
            onComplete(data) {
                console.log('定位结果：' + data.position) //经纬度信息
                let lnglat = data.position;
                let marker = new AMap.Marker({  //创建标记
                    position: new AMap.LngLat(lnglat.lng, lnglat.lat)
                })
                // this.map.clearMap()// 清除所有覆盖物（点标志）
                this.map.add(marker)// 添加点标志
                let that = this
                //经纬度转换为中文地址详情
                // that.geocoder.getAddress(lnglat, function (status, result) {
                //     if (status === 'complete' && result.regeocode) {
                //         that.address = result.regeocode.formattedAddress;
                //         that.showInfoWindow(marker);//自定义信息窗体
                //     } else {
                //         that.$message.error('根据经纬度查询地址失败')
                //     }
                // })
            },
            //解析定位错误信息
            onError(data) {
                this.getLngLatLocation()
            },
            //在获取具体定位失败时调用的代码：（非精准定位！！！）
            getLngLatLocation() {
                const that = this;
                that.geolocation.getCityInfo(function (status, result) {
                    if (status === 'complete') {
                        console.log(status,'status')
                        console.log(result,'result')
                        let data = result.center
                        that.address = result.province + result.city;
                        console.log(data,'showLocation')
                        that.showLocation(data)
                    } else {
                        that.$message.error('获取地址失败')
                    }
                })
            },
            //新增标记
            showLocation(data){
                let marker = new AMap.Marker({
                    position: new AMap.LngLat( data[0],data[1]) //参数为经纬度
                })
                // this.map.clearMap()// 清除所有覆盖物（点标志）
                this.map.add(marker)// 添加点标志
                this.map.setCenter([data[0],data[1]]);
                // this.showInfoWindow(marker);//自定义信息窗体
            },
            //泵房位置
            addLocation(){
                if (this.pumpInfo && this.pumpInfo.length > 0) {
                    this.pumpInfo.forEach((e)=>{
                        var icon = new AMap.Icon({

                            image: 'https://oss.nmfire.cn/2024/10/23/10911696910573_.pic_20241023153337A454.jpg',  // Icon的图像
                            imageSize: new AMap.Size(20, 30)   // 根据所设置的大小拉伸或压缩图片
                        });
                        let marker = new AMap.Marker({
                            position: new AMap.LngLat( e.longitude,e.latitude), //参数为经纬度
                            extData: {id:e.id},
                            icon: icon,
                        })
                        this.mapmarker=marker
                        this.map.add(marker)// 添加点标志
                        marker.on('click', this.markerClick)
                    })
                }
            },
            markerClick(e){
                this.pumpInfo.forEach(v=>{
                    if(v.id==e.target.getExtData().id){
                        this.mapwindow=v
                    }
                })
                this.showInfoWindow(this.mapmarker,1);//自定义信息窗体
            },
            //自定义信息窗体
            showInfoWindow(marker,e){
                if(e){
                    this.infoWindow = new AMap.InfoWindow({
                        isCustom: true, //是否自定义信息窗体
                        content:   `<div style="background-color: white;padding: 15px; border-radius: 10px;border: 1px solid #cccccc;width: 180px">
            <div style="display: flex;align-items: center;justify-content: space-between">
                <div style="font-weight: bold">${this.mapwindow.title}</div>
            </div>
            <div style="margin: 10px 0"><img width="100%" height="80px" src="${this.mapwindow.url}"></div>
            <div style="font-size: 13px">${this.$t('i18n_67')}：${this.mapwindow.location}</div>
            <div style="font-size: 13px;display: flex;margin: 10px 0;color:#bc000f">
                ${this.$t('i18n_68')}：
                <div>
                    <div>${this.mapwindow.longitude}</div>
                </div>
            </div>
            <div style="font-size: 13px;display: flex;margin: 10px 0;color:#bc000f">
                ${this.$t('i18n_69')}：
                <div>
                    <div>${this.mapwindow.latitude}</div>
                </div>
            </div>
            <div id="ckbf" onclick='quickHandle()' style="padding: 5px;color: white;background:#333333;border-radius: 10px;text-align: center;margin: 0 0 10px;cursor: pointer;">${this.$t('i18n_70')}</div>
            <div id="jrbf" onclick='jumpClick()' style="padding: 5px;color: white;background:#333333;border-radius: 10px;text-align: center;cursor: pointer; ">${this.$t('i18n_71')}</div>
        </div>`,
                        closeWhenClickMap: true,
                        zIndex: 999,
                        offset: new AMap.Pixel(16, -35)
                    });
                    this.infoWindow.open(this.map, [this.mapwindow.longitude,this.mapwindow.latitude]);
                    let that=this
                    // document.getElementById("gb").onclick = function(e) {
                    //     that.infoWindow.close();
                    // };
                    setTimeout(() => {
                        document.getElementById("ckbf").onclick = function(e) {
                            console.log(that.mapwindow,123123)
                            that.$refs.Add.start(that.mapwindow)
                        };
                        document.getElementById("jrbf").onclick = function(e) {
                            let query = { pumpRoomId: that.mapwindow.id };
                            that.jumpHandle("./pumpHouse", query);
                        };
                    }, 1000);

                    return
                }

                let infoWindow = new AMap.InfoWindow({
                    isCustom: true, //是否自定义信息窗体
                    content:  `<div style="background-color: white;padding: 0 5px; border-radius: 5px;border: 1px solid #cccccc;"> 地址：${this.address}</div>`,
                    closeWhenClickMap: true,
                    zIndex: 999,
                    offset: new AMap.Pixel(16, -35)
                });
                // infoWindow.open(this.map, marker.getPosition());
            },

            init(e){
                console.log('sj',e)
                this.pumpInfo = e
                this.map = null
                this.initMap()
            },
            jumpHandle(e, query) {
                if (query) {
                    this.$router.push({ path: e, query });
                } else {
                    this.$router.push({ path: e });
                }
            },
            quickHandle(){
                let that = this
                that.$refs.Add.start(that.mapwindow)
            },
            jumpClick(){
                let that = this
                let query = { pumpRoomId: that.mapwindow.id };
                that.jumpHandle("./pumpHouse", query);
            },
        },
        mounted() {
            window.quickHandle = this.quickHandle;
            window.jumpClick = this.jumpClick;
        },
    };
</script>

<style lang="scss" scoped>
    #bai-du-map {
        overflow: hidden;
        width: 100%;
        height: 100%;
        margin: 0;
        font-family: "微软雅黑";
    }
    .positonbtn{
        position: absolute;
        z-index: 98;
        right:20px;
        bottom:20px;
        background: #FFF;
        border-radius: 50%;
        width: 24px;
        height: 25px;
        cursor: pointer;
        i{
            font-size: 25px;
            color: #aba8a8;
        }
    }
    .map-top{
        position: absolute;
        z-index: 99;
        background: linear-gradient(90deg, #E10100 0%, #D80100 25%, rgba(187,1,0,0) 100%);
        width: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        ::v-deep{
            .el-input__inner{
                border-radius: 21px;
                width: 60%;
            }
        }
        span{
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 30px;
            white-space: nowrap;
            margin-right: 30px;
        }
    }
    .el-autocomplete-suggestion li{
        line-height: 20px;
    }
    .my-autocomplete {
        li {
            line-height: 20px;
            padding: 7px;

            .name {
                text-overflow: ellipsis;
                line-height: 20px!important;
                overflow: hidden;
            }
            .addr {
                font-size: 12px;
                line-height: 20px!important;
                color: #b4b4b4;
            }

            .highlighted .addr {
                color: #ddd;
            }
        }
    }
</style>
<style>
    /* 隐藏高德logo  */
    .amap-logo {
        display: none !important;
    }
    /* 隐藏高德版权  */
    .amap-copyright {
        display: none !important;
    }
</style>
